import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      // main: "#5184EC"
      main: "#8ABA5C"
    },
    secondary: {
      // main: "#2AB38E",
      main: "#16477E",
    },
    warning: {
      main: "#F8AF1E",
    },
    info: {
      main: "#F3F3F3",
    },
  },
  typography: {
    allVariants: {
      textTransform: "none",
    },
    button: {
      textTransform: "none",
    },
  },
});
