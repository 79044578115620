import React, { useEffect, useMemo, useState } from "react";
import { useGetAllUsersDataQuery } from "../../RTKQuery/CsvDataService/csvDataApi";
import Loader from "../Loader/Loader";
import PageComponentHeader from "../PageComponentHeader";
import FilterDrawer from "../UIKit/Drawer";
import ConditionalFilters from "../ConditionalFilters";
import { useLazyExportUsersQuery } from "../../RTKQuery/CsvDataService/csvDataApi";
import ExportButton from "../UIKit/Buttons/Export";
import DataTable from "../UIKit/CollapsibleTable";
// import { userListColumns } from "../../constants/tableColumns";
// import MuiMultipleSelect from "../UIKit/MultiSelect";
import { UserDataColumns } from "../../constants/componentConstants";
import Upload from "../Modals/Upload/Upload";
import MappingModal from "../../pages/Dashboard/components/MappingModal/MappingModal";
import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useSelector } from "react-redux";

export default function UsersList({ filters, setFilters }) {
  // const myColumns = localStorage.getItem("columns")?.split(",");
  const files = useSelector((state) => state.file.files);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [sorting, setSorting] = useState([]);
  const [usersData, setUsersData] = useState([]);
  // Filters
  const [closeFilterMenu, setCloseFilterMenu] = useState(true);
  const [filterCount, setFilterCount] = useState(1);
  const [conditionCount, setConditionCount] = useState(0);
  const [subConditionCount, setSubConditionCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [triggerExport, { isLoading }] = useLazyExportUsersQuery();

  const [uploadModal, setUploadModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [compareModal, setCompareModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [uploadTypeApollo, setUploadTypeApollo] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState(false);
  const [showApollo, setShowApollo] = useState(false);

  //Alert props
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const defaultFilter = [
    {
      id: "f-1",
      first: true,
      conditions: [
        {
          id: "f-10",
          first: true,
          subConditions: [],
          filter: "f-1",
        },
      ],
    },
  ];
  const [filterData, setFilterData] = useState(defaultFilter);

  // Applying Filters
  const {
    data,
    loading: dataLoading,
    refetch,
  } = useGetAllUsersDataQuery(
    {
      pagination,
      // columns: savedColumns,
      sorting,
      filters: JSON.stringify(filters),
    }
    // { skip: !shouldFetchData }
  );

  const [loading, setLoading] = useState(null);

  const handleApplyFilters = () => {
    // setCurrentPage(0)
    setPagination({ ...pagination, pageIndex: 0 });
    setFilters(filterData);
    setCloseFilterMenu(true);
  };

  const handleResetFilters = () => {
    // setCurrentPage(0)
    setPagination({ ...pagination, pageIndex: 0 });
    setFilterData([
      {
        id: "f-1",
        first: true,
        conditions: [
          {
            id: "f-10",
            first: true,
            subConditions: [],
            filter: "f-1",
          },
        ],
      },
    ]);
    setFilters([]);
    setCloseFilterMenu(true);
  };
  const handleExport = async () => {
    try {
      await triggerExport(filters).unwrap();
    } catch (error) {
      console.error("Error exporting users:", error);
    }
  };

  const handleUploadModal = (bool) => {
    setShowMap(bool);
    setUploadModal(true);
  };

  const handleUploadUsers = () => {
    setUploadTypeApollo(false);
    handleUploadModal(true);
  };

  const userListColumns = [
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: false,
    // size:100
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    enableSorting: false,
    // size: 100,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    enableSorting: false,
    // size: 100,
  },
  {
    accessorKey: "totalCampaigns",
    header: "Campaigns",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalSent",
    header: "Sent",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalOpens",
    header: "Opens",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalClicks",
    header: "Clicks",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalBounced",
    header: "Bounced",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalUnsubScribed",
    header: "Unsubscribed",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "totalBlocked",
    header: "Blocked",
    // enableSorting: false
    size: 60,
  },
  {
    accessorKey: "openRate",
    header: "Open Rate",
    Cell: ({ cell }) => cell?.getValue() + "%",
    // enableSorting: false
    size: 80,
  },
];


  useEffect(() => {
    // refetch()
    if (dataLoading) {
      console.log("loading");
      setLoading(dataLoading);
    }
    if (data) {
      setLoader(false);
      setUsersData(data.users);
    }
  }, [data, usersData, dataLoading]);
  
  // console.log("Files++++++++++",files);

  // useEffect(() => {
  //   refetch();
  // }, [files]);

  // const tableColumns = useMemo(() => filteredColumns, [filteredColumns]);

  return (
    <div>
      <PageComponentHeader allowBack={false} pageTitle={"Users Management"}>
        <Button
          startIcon={<UploadFileIcon />}
          color="primary"
          variant="contained"
          sx={{ color: "#F3F3F3" }}
          onClick={handleUploadUsers}
        >
          Upload CSV
        </Button>
        <ExportButton onClick={handleExport} isLoading={isLoading} />
        <FilterDrawer
          close={closeFilterMenu}
          setClose={setCloseFilterMenu}
          filters={filters}
          setCloseFilterMenu={setCloseFilterMenu}
        >
          <>
            <ConditionalFilters
              onReset={handleResetFilters}
              onSubmit={handleApplyFilters}
              filtersArray={filterData}
              setFiltersArray={setFilterData}
              setCloseFilterMenu={setCloseFilterMenu}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              conditionCount={conditionCount}
              setConditionCount={setConditionCount}
              subConditionCount={subConditionCount}
              setSubConditionCount={setSubConditionCount}
              defaultFilter={defaultFilter}
            />
          </>
        </FilterDrawer>
      </PageComponentHeader>
      {data?.users.length === 0 ? (
        <h3 className="noData">No Record Found</h3>
      ) : !loader && data?.users.length > 0 ? (
        <div style={{ marginTop: "1rem" }}>
          <DataTable
            columns={userListColumns}
            data={usersData}
            totalData={data.total}
            sorting={sorting}
            setSorting={setSorting}
            pagination={pagination}
            setLoader={setLoader}
            setPagination={setPagination}
          />
        </div>
      ) : (
        <div className="noData">
          <Loader />
        </div>
      )}
      <Upload
        open={uploadModal}
        setOpen={setUploadModal}
        setMapModal={setMapModal}
        showMap={showMap}
        setShowMap={setShowMap}
        uploadTypeApollo={uploadTypeApollo}
        BtnText={uploadTypeApollo ? "Upload Apollo Csv" : "Upload Csv"}
        uploading={uploading}
        setUploading={setUploading}
        refetchData={refetch}
      />
      <MappingModal
        open={mapModal}
        setOpen={setMapModal}
        uploadTypeApollo={uploadTypeApollo}
        setUploading={setUploading}
        setUploadModal={setUploadModal}
        setShowAlert={setShowAlert}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
    </div>
  );
}
