import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import {
  useGetCampaignUsersQuery,
  useLazyExportCampaignUsersQuery,
} from "../../RTKQuery/CsvDataService/csvDataApi";
import { useGetSingleCampaignQuery } from "../../RTKQuery/CampaignService/campaignApi";
import DataTable from "../UIKit/CollapsibleTable";
import PageComponentHeader from "../PageComponentHeader";
import ExportButton from "../UIKit/Buttons/Export";
import UploadCampaignStats from "../UIKit/Buttons/UploadCampaignStats";
import FilterDrawer from "../UIKit/Drawer";
import ConditionalFilters from "../ConditionalFilters";
import EmailTextModal from "../Modals/EmailTextModal";
import { CSVLink } from "react-csv";
import { generateCampaignCSV } from "../../utils/csv";
import { campaignDetailsColumns } from "../../constants/tableColumns";
// import { userListColumns } from "../../constants/tableColumns";

const CampaignDetailsComponent = () => {
  const { id } = useParams();

  // State
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [loader, setLoader] = useState(true);
  const [conditionCount, setConditionCount] = useState(0);
  const [subConditionCount, setSubConditionCount] = useState(0);

  const defaultFilter = [
    {
      id: "f-1",
      first: true,
      conditions: [
        {
          id: "f-10",
          first: true,
          subConditions: [],
          filter: "f-1",
        },
      ],
    },
  ];
  const [filterData, setFilterData] = useState(defaultFilter);
  const [closeFilterMenu, setCloseFilterMenu] = useState(true);
  const downloadRef = useRef();

  // RTK Query hooks
  const { data: campaignData, isLoading: fetching } =
    useGetSingleCampaignQuery(id);
  const { data, isLoading } = useGetCampaignUsersQuery({
    id,
    page: pagination.pageIndex,
    rows: pagination.pageSize,
    filters: JSON.stringify(filters),
  });

  const [triggerExport, { isLoading: exportLoading }] =
    useLazyExportCampaignUsersQuery();

  // Handlers
  const handleApplyFilters = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setFilters(filterData);
    setCloseFilterMenu(true);
  };

  const handleResetFilters = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    setFilterData([
      {
        id: "f-1",
        first: true,
        conditions: [
          {
            id: "f-10",
            first: true,
            subConditions: [],
            filter: "f-1",
          },
        ],
      },
    ]);
    setFilters([]);
    setCloseFilterMenu(true);
  };

  const handleExport = async () => {
    try {
      await triggerExport({ id, filters }).unwrap();
    } catch (error) {
      console.error("Error exporting users:", error);
    }
  };

  // Effects
  useEffect(() => {
    if (data) {
      setUsers(data.users);
      setEmail(data?.users[0]?.campaigns[0]?.campaignBody || "");
      setLoader(false);
    }
  }, [data]);

  // Generate CSV data
  const csvData = generateCampaignCSV(users);

  return (
    <>
      <PageComponentHeader
        pageTitle={`${campaignData?.campaignTitle}` || ""}
        allowBack
      >
        {/* {data?.users[0]?.campaigns[0]?.campaign === "lemlist" && (
          <EmailTextModal body={email} />
        )} */}
        <ExportButton onClick={handleExport} isLoading={exportLoading} />
        {data?.users[0]?.campaigns[0]?.campaign === "lemlist" && (
          <UploadCampaignStats id={id} />
        )}
        <FilterDrawer
          close={closeFilterMenu}
          setClose={setCloseFilterMenu}
          setCloseFilterMenu={setCloseFilterMenu}
          filters={filters}
        >
          <ConditionalFilters
            campaignUsers
            onReset={handleResetFilters}
            onSubmit={handleApplyFilters}
            conditionCount={conditionCount}
            setConditionCount={setConditionCount}
            subConditionCount={subConditionCount}
            setSubConditionCount={setSubConditionCount}
            filtersArray={filterData}
            setFiltersArray={setFilterData}
            setCloseFilterMenu={setCloseFilterMenu}
            defaultFilter={defaultFilter}
          />
        </FilterDrawer>
      </PageComponentHeader>

      {loader ? (
        <div className="noData">
          <Loader />
        </div>
      ) : users?.length > 0 ? (
        <DataTable
          columns={campaignDetailsColumns}
          data={users}
          totalData={data?.total || 0}
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          setLoader={setLoader}
        />
      ) : (
        <h3 className="noData">No Record Found</h3>
      )}

      <CSVLink
        headers={csvData?.headers || []}
        data={csvData?.data || ""}
        filename="Campaign"
        style={{ display: "none" }}
        ref={downloadRef}
      />
    </>
  );
};

export default CampaignDetailsComponent;
