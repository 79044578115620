import { Box, FormControl, IconButton } from "@mui/material";
import React from "react";
import FilterSelect from "../UIKit/Select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SubFilter from "./SubFilter";
import SecondaryButton from "../../UIKit/Buttons/Secondary";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Filter({
  data,
  filtersArray,
  setFiltersArray,
  conditionsArray,
  handleAddNewSubCondition,
  removeSubCondition,
  removeCondition,
  campaignUsers
}) {
  // Adding values
  const addItemKeyValueToCondition = (
    conditionId,
    subConditionId,
    key,
    value
  ) => {
    setFiltersArray((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.conditions) {
          return {
            ...filter,
            conditions: filter.conditions.map((condition) => {
              if (condition.id === conditionId) {
                if (subConditionId) {
                  return {
                    ...condition,
                    subConditions: condition.subConditions.map(
                      (subCondition) => {
                        if (subCondition.id === subConditionId) {
                          return { ...subCondition, [key]: value };
                        }
                        return subCondition;
                      }
                    ),
                  };
                } else {
                  return { ...condition, [key]: value };
                }
              }
              return condition;
            }),
          };
        }
        return filter;
      });
    });
  };

  // conditionsArray && console.log('conditions ==========>>>>>>', filtersArray)
  return (
    <>
      {conditionsArray &&
        conditionsArray?.length > 0 &&
        conditionsArray?.map(
          (item) =>
            item.filter === data.id && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: ".5rem",
                  margin: "10px 0",
                  alignItems: "inherit",
                  padding: "10px 0",
                }}
                key={`c-${item.id}`}
              >
                {!item.first && (
                  <FormControl
                    fullWidth
                    style={{
                      minWidth: "5rem",
                      maxWidth: "5rem",
                      margin: "2px 0",
                    }}
                  >
                    <FilterSelect
                      options={["AND", "OR"]}
                      value={item?.operator}
                      size={"small"}
                      width={"5rem"}
                      fontSize={"12px"}
                      label={"and/or"}
                      id={"campaign-filter-companies"}
                      onChange={(e) => {
                        addItemKeyValueToCondition(
                          item.id,
                          null,
                          "operator",
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                )}

                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <SubFilter
                      data={item}
                      conditionId={item.id}
                      conditionsArray={conditionsArray}
                      setValue={addItemKeyValueToCondition}
                      campaignUsers = {campaignUsers}
                    />
                    {!item.first && (
                      <IconButton onClick={() => removeCondition(item.id)}>
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                  </div>
                  {item.subConditions &&
                    item.subConditions?.length > 0 &&
                    item.subConditions.map((subItem) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".2rem",
                        }}
                        key={`sc-${subItem.id}`}
                      >
                        <SubFilter
                          campaignUsers = {campaignUsers}
                          data={subItem}
                          conditionId={item.id}
                          subConditionId={subItem.id}
                          conditionsArray={conditionsArray}
                          setValue={addItemKeyValueToCondition}
                          setFiltersArray={setFiltersArray}
                          filtersArray={filtersArray}
                        />
                        <IconButton
                          onClick={() =>
                            removeSubCondition(item.id, subItem.id)
                          }
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>
                {!item.first && (
                  <SecondaryButton
                    fontSize={"12px"}
                    text={"SubCondition"}
                    variant={"text"}
                    color={"#2AB38E"}
                    icon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddNewSubCondition(item.id)}
                  />
                )}
              </Box>
            )
        )}
    </>
  );
}
