import React, { useEffect, useState } from "react";
import PageComponentHeader from "../PageComponentHeader";
import Loader from "../Loader/Loader";
import DataTable from "../UIKit/CollapsibleTable";
import { filesTableHeader } from "../../constants/componentConstants";
import { useGetFileStatusesQuery } from "../../RTKQuery/FileService/fileApi";
import ActionButtons from "./ActionButton";
import {
  subscribeToFileStatus,
  unsubscribeFromFileStatus,
} from "../../Services/websockets";
import CircularWithValueLabel from "../UIKit/CircularProgressWithLabel";
import { useSelector, useDispatch } from "react-redux";

export default function FilesList() {
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);

  // Fetch data from the API
  const { data, isLoading, isError, refetch } = useGetFileStatusesQuery(
    {
      page: currentPage,
      rows,
    },
    { refetchOnMountOrArgChange: true }
  );

  const files = useSelector((state) => state.file.files);
  const dispatch = useDispatch();

  // Synchronize tableData with API response
  // useEffect(() => {
  //   if (data) {
  //     const updatedData = data.map((file) => ({
  //       ...file,
  //       progress:
  //         file.totalCampaigns && file.processedCampaigns
  //           ? Math.round((file.processedCampaigns / file.totalCampaigns) * 100)
  //           : 0,
  //     }));
  //     setTableData(updatedData);
  //   }
  // }, [data]);
  // Update the tableData based on the global files state

  // Synchronize tableData with API response or Redux store
  useEffect(() => {
    if (data) {
      // const updatedData = data.map((file) => ({
      //   ...file,
      //   progress:
      //     file.totalCampaigns && file.processedCampaigns
      //       ? Math.round((file.processedCampaigns / file.totalCampaigns) * 100)
      //       : 0,
      //   status: file.status || "pending", // Ensure status is available
      // }));
      setTableData(data);
    }
  }, [data]);
  useEffect(() => {
    if (files && files.length > 0) {
      setTableData((prevData) =>
        prevData.map((row) => {
          const matchingFile = files.find((file) => file.id === row.id);
          if (matchingFile) {
            return {
              ...row,
              progress: matchingFile.progress,
              status: matchingFile.status,
            };
          }
          return row;
        })
      );
    }
  }, [files]);

  // Listen for WebSocket updates
  // useEffect(() => {
  //   subscribeToFileStatus(({ event, data }) => {
  //     console.log(
  //       `Received WebSocket Update: event=${event}, data=${JSON.stringify(
  //         data
  //       )}`
  //     );
  //     setTableData((prev) =>
  //       prev.map((row) => {
  //         if (row.id === data.id) {
  //           const { totalCampaigns, processedCampaigns, status } = data;
  //           return {
  //             ...row,
  //             status,
  //             progress: totalCampaigns
  //               ? Math.round((processedCampaigns / totalCampaigns) * 100)
  //               : 0,
  //           };
  //         }
  //         return row;
  //       })
  //     );
  //   });

  //   return () => {
  //     unsubscribeFromFileStatus();
  //   };
  // }, []);

  const handlePaginationChange = ({ pageIndex, pageSize }) => {
    setLoader(true);
    setCurrentPage(pageIndex);
    setRows(pageSize);
  };

  if (isLoading || isLoader) {
    return (
      <div className="noData">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <h3 className="noData">Error loading data. Please try again later.</h3>
    );
  }

  const FilesColumns = [
    {
      accessorKey: "originalName",
      header: "Name",
      enableSorting: false,
    },
    {
      accessorKey: "createdAt",
      header: "Uploaded on",
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      enableSorting: false,
      Cell: ({ row }) => {
        const statusColor =
          row.original.status === "error"
            ? "red"
            : row.original.status === "completed"
            ? "green"
            : "orange";
        return <div style={{ color: statusColor }}>{row.original.status}</div>;
      },
    },
    {
      accessorKey: "progress",
      header: "Progress",
      enableSorting: false,
      Cell: ({ row }) => {
        const progress = row.original.progress || 0;
        const status = row.original.status;
        return (
          <div>
            {status !== "completed" ? (
              <CircularWithValueLabel value={progress} />
            ) : (
              // progress
              "Completed"
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "_",
      header: "Actions",
      enableSorting: false,
      Cell: ({ row }) => (
        <div>
          <ActionButtons data={row} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageComponentHeader pageTitle="My Files" />
      {tableData && tableData.length > 0 ? (
        <DataTable
          columns={FilesColumns}
          data={tableData}
          selectable={false}
          setSorting={setSorting}
          totalData={data?.length || 0}
          sorting={sorting}
          pagination={{ pageIndex: currentPage, pageSize: rows }}
          setPagination={handlePaginationChange}
          density={"comfortable"}
          enableCollapsibleRow={false}
          setLoader={setLoader}
        />
      ) : (
        <h3 className="noData">No Record Found</h3>
      )}
    </div>
  );
}
