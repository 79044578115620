import React, { useState, useEffect } from "react";
import ActionButton from "./ActionButton";
import { useGetCampaignsQuery } from "../../RTKQuery/CampaignService/campaignApi";
import Loader from "../Loader/Loader";
import PageComponentHeader from "../PageComponentHeader";
import { Box, Button, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import Upload from "../Modals/Upload/Upload";
import MuiSnackbar from "../UIKit/Snackbar";
import {
  searchField,
  searchInput,
  buttonsDiv,
} from "../../pages/Dashboard/styles";
import "./style.css";
import { useSelector } from "react-redux";
import DataTable from "../UIKit/CollapsibleTable";
import CampaignNameGenerationModal from "../Modals/CampaignName";
// import RefreshButton from "../UIKit/Buttons/RefreshStats";

export default function Campaigns() {
  const [currentPage, setCurrentPage] = useState(0);
  const files = useSelector((state) => state.file.files);

  // upload props
  const navigate = useNavigate();
  const [uploadModal, setUploadModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [rows, setRows] = useState(10);
  //Alert props
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  //Table Props
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [sorting, setSorting] = useState([]);
  const [loader, setLoader] = useState(true);

  //Search Props
  const [search, setSearch] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = (id) => {
    navigate(`/campaign/details/${id}`);
    // console.log(id);
  };

  const {
    data,
    isLoading: loading,
    refetch,
  } = useGetCampaignsQuery({
    pagination,
    sorting,
    searchQuery,
  });

  const CampaignsColumns = [
  {
      accessorKey: " ",
      header: " ",
      enableSorting: false,
      size:30,
      Cell: ({ row }) => <ActionButton id={row.original.id} />,
    },
    {
      accessorKey: "campaignTitle",
      header: "Title",
      enableSorting: false,
      size: 200,
      Cell: ({ cell }) => (
        <div className="title-div" onClick={() => handleClick(cell.row.id)}>
          {cell?.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "campaignSubject",
      header: "Subject",
      enableSorting: false,
      size: 200,
    },
    {
      accessorKey: "totalProcessed",
      header: "Processed",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "totalSent",
      header: "Sent",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "totalOpen",
      header: "Opened",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "clicks",
      header: "Clicked",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "bounced",
      header: "Bounced",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "blocked",
      header: "Blocked",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "unsubScribed",
      header: "Unsubscribed",
      enableSorting: true,
      size: 40,
    },
    {
      accessorKey: "openRate",
      header: "Open Rate",
      enableSorting: true,
      size: 40,
      Cell: ({ cell }) => cell?.getValue() + "%",
    },
    {
      accessorKey: "clickRate",
      header: "Click Rate",
      enableSorting: true,
      size: 40,
      Cell: ({ cell }) => cell?.getValue() + "%",
    }
  ];

  const handleUploadModal = (bool) => {
    setShowMap(bool);
    setUploadModal(true);
  };

  // Debounce Effect: Wait until user stops typing
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchQuery(inputValue); // Update searchQuery after 500ms
    }, 500); // 500ms delay

    return () => clearTimeout(delayDebounce); // Cleanup on input change
  }, [inputValue]); // Runs when inputValue changes

  // Update temporary input state on typing
  const handleSearchChange = (e) => {
    setInputValue(e.target.value);
  };

  // Force search when clicking the search button
  const handleSearch = () => {
    setSearchQuery(inputValue);
  };

  useEffect(() => {
    refetch();
  }, [data, files]);

  // console.log(data);

  return (
    <>
      <PageComponentHeader pageTitle={"Campaigns"}>
        <Box sx={searchField}>
          <input
            type="text"
            style={searchInput}
            className="inputSearch"
            placeholder="Search by Campaign Title..."
            onChange={handleSearchChange}
          />
          <IconButton onClick={handleSearch}>
            <img src="Research.png" alt="" />
          </IconButton>
        </Box>
        <Box sx={buttonsDiv}>
          <CampaignNameGenerationModal />
          <Button
            startIcon={<UploadFileIcon />}
            color="primary"
            variant="contained"
            sx={{ color: "#F3F3F3" }}
            onClick={() => handleUploadModal(false)}
          >
            Upload Sendgrid Stats
          </Button>
          {/* <RefreshButton/> */}
        </Box>
      </PageComponentHeader>
      <Upload
        sendGrid={true}
        open={uploadModal}
        setOpen={setUploadModal}
        showMap={showMap}
        setShowMap={setShowMap}
        uploading={uploading}
        setUploading={setUploading}
        setShowAlert={setShowAlert}
        setSeverity={setSeverity}
        setMessage={setMessage}
      />
      <MuiSnackbar
        show={showAlert}
        setShow={setShowAlert}
        severity={severity}
        message={message}
      />
      {loading ? (
        <div className="noData">
          <Loader />
        </div>
      ) : data?.campaigns.length > 0 ? (
        <DataTable
          columns={CampaignsColumns}
          data={data.campaigns}
          totalData={data.total}
          sorting={sorting}
          setSorting={setSorting}
          pagination={pagination}
          setLoader={setLoader}
          setPagination={setPagination}
          enableCollapsibleRow={false}
        />
      ) : (
        <h3 className="noData">No Record Found</h3>
      )}
    </>
  );
}
