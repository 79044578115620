import React from "react";
import { useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import CircularWithValueLabel from "../CircularProgressWithLabel";
import { useFileProgress } from "../../../Hooks/useFileProgress";

const FileProgressAlerts = () => {
  useFileProgress(); // Set up WebSocket listener

  // Select the files state from Redux
  const files = useSelector((state) => state.file.files);
  console.log("Alert  ------------",files)

  // Check if any file is still processing
  const alertVisible = React.useMemo(
    () => files.some((file) => file.status.toLowerCase() !== "completed"),
    [files]
  );

  return (
    alertVisible && (
      <Box
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          zIndex: 9999,
        }}
      >
        {files.map((file) => (
          <Paper
            key={file.id}
            elevation={3}
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              minWidth: "250px",
            }}
          >
            <Box>
              <Typography variant="body1">{file.name}</Typography>
              <Typography variant="body2">
                Status: {file.status || "Processing"}
              </Typography>
            </Box>
            {file.status === 'completed' ? <>100%</> : <CircularWithValueLabel value={file.progress} />}
          </Paper>
        ))}
      </Box>
    )
  );
};

export default FileProgressAlerts;
