import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Grid,
  Divider,
  // IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BasicDatePicker from "../../UIKit/DatePicker";
import dayjs from "dayjs";
import { useCreateCampaignMutation } from "../../../RTKQuery/CampaignService/campaignApi";
import Loader from "../../Loader/Loader";

// Formik validation schema
const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  subject: Yup.string().required("Subject is required"),
  startDate: Yup.date().required("Date is required"),
  keyword1: Yup.string(),
  keyword2: Yup.string(),
  keyword3: Yup.string(),
});

const CampaignNameGenerationModal = () => {
  const [autoGeneratedTitle, setAutoGeneratedTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [epochTime, setEpochTime] = useState(null);
  const [created, setCreated] = useState(false);
  const [data, setData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [createCampaign, { isLoading: creating }] = useCreateCampaignMutation();

  const formik = useFormik({
    initialValues: {
      title: "",
      subject: "",
      startDate: null,
      keyword1: "",
      keyword2: "",
      keyword3: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        campaignTitle: autoGeneratedTitle,
        campaignSubject: values.subject,
        startDate: values.startDate,
      };

      try {
        const response = await createCampaign(payload).unwrap();
        setShowAlert(true);
        setData(response);
        setCreated(true);
      } catch (error) {
        console.error("Failed to create campaign", error);
      }
    },
  });

  useEffect(() => {
    const { title, keyword1, keyword2, keyword3, startDate } = formik.values;

    // Format the date
    const formattedDate = startDate
      ? dayjs(startDate).format("DD.MM.YYYY")
      : "";

    // Dynamically build the title without trailing underscores
    const formattedTitle = [
      title,
      keyword1,
      keyword2,
      keyword3,
      formattedDate,
      epochTime,
    ]
      .filter((value) => value) // Remove empty values
      .join("_"); // Join remaining values with "_"

    setAutoGeneratedTitle(formattedTitle);
  }, [formik.values, epochTime]);

  const handleOpen = () => {
    setEpochTime(Date.now()); // Set epoch time only when the modal opens
    setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(autoGeneratedTitle);
    setSnackbarOpen(true); // Open Snackbar
  };

  const onClose = () => {
    setData(null);
    setCreated(false);
    formik.resetForm();
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined">
        Create Campaign
      </Button>
      <Modal open={open} onClose={onClose}>
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "auto",
            width: "600px",
            minHeight: "200px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <h2>New SendGrid Campaign</h2>
          <Divider style={{ margin: "15px 0" }} />
          {!created && !creating ? (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    label="Campaign Title"
                    value={autoGeneratedTitle}
                    fullWidth
                    size="small"
                    disabled
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "15px 0" }} />

              <TextField
                label="Campaign Name"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                fullWidth
                size="small"
                margin="normal"
              />

              <TextField
                label="Campaign Subject"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
                fullWidth
                size="small"
                margin="normal"
              />

              <BasicDatePicker
                label="Date"
                value={formik.values.startDate}
                onChange={(date) => formik.setFieldValue("startDate", date)}
              />

              {["keyword1", "keyword2", "keyword3"].map((keyword, index) => (
                <TextField
                  key={index}
                  label={`Keyword ${index + 1}`}
                  name={keyword}
                  value={formik.values[keyword]}
                  onChange={formik.handleChange}
                  size="small"
                  error={
                    formik.touched[keyword] && Boolean(formik.errors[keyword])
                  }
                  helperText={formik.touched[keyword] && formik.errors[keyword]}
                  fullWidth
                  margin="normal"
                />
              ))}

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6}>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ color: "white" }}
                  >
                    Save Campaign
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : created && data ? (
            <>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    // label="Campaign Title"
                    value={autoGeneratedTitle}
                    fullWidth
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button onClick={handleCopy} startIcon={<ContentCopyIcon />}>
                    Copy
                  </Button>
                </Grid>
              </Grid>
              <Divider style={{ margin: "15px 0" }} />
              <Button
                onClick={onClose}
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: "white" }}
              >
                Close
              </Button>
            </>
          ) : creating ? (
            <>
              <Loader />
            </>
          ) : (
            <></>
          )}
          {/* {showAlert && (
            <Alert style={{marginTop:'1rem'}} variant="outlined" severity="success">
              Campaign created Successfully
            </Alert>
          )} */}
        </div>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Text copied to clipboard"
      />
    </div>
  );
};

export default CampaignNameGenerationModal;
