import DuplicateDataModal from "../components/Modals/DuplicateModal";
import { customDateFormat } from "../utils/helperFunctions";

export const userListColumns = [
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: false,
    grow: false,
    minSize: 50,
    maxSize: 100,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    enableSorting: false,
    size: 40,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    enableSorting: false,
    size: 40,
  },
  // {
  //   accessorFn: (row) => {
  //     // If emailVerification is empty or the verified field is not present, return false
  //     return row?.emailVerification?.length > 0
  //       ? row.emailVerification[0].verified
  //       : false;
  //   },
  //   header: "Email Status",
  //   Cell: ({ cell }) => {
  //     const value = cell?.getValue();
  //     return value ? "Verified" : "Not Verified";
  //   },
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "state",
  //   header: "State",
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "gender",
  //   header: "Gender",
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "country",
  //   header: "Country",
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "createdAt",
  //   header: "Created At",
  //   // enableSorting: false
  //   Cell: ({ cell }) => {
  //     const value = cell.getValue();
  //     return customDateFormat(value, "YYYY-MM-DD HH:mm:ss");
  //   },
  // },
  // {
  //   accessorKey: "blackListed",
  //   header: "Blacklisted",
  //   Cell: ({ cell }) => (cell?.getValue() ? "True" : "False"),
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "isRedFlagged",
  //   header: "Red Flagged",
  //   Cell: ({ cell }) => (cell?.getValue() ? "True" : "False"),
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "verified",
  //   header: "Verified",
  //   Cell: ({ cell }) => (cell?.getValue() ? "True" : "False"),
  //   enableSorting: false,
  // },
  // {
  //   accessorKey: "total",
  //   header: "Total",
  //   // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  // },
  // {
  //   accessorKey: "convRate",
  //   header: "Conv Rate",
  //   // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  // },
  // {
  //   accessorKey: "convValid",
  //   header: "Conv Valid",
  //   // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  // },
  {
    accessorKey: "totalCampaigns",
    header: "Campaigns",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalSent",
    header: "Sent",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalOpens",
    header: "Opens",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalClicks",
    header: "Clicks",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalBounced",
    header: "Bounced",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalUnsubScribed",
    header: "Unsubscribed",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "totalBlocked",
    header: "Blocked",
    // enableSorting: false
    size: 40,
  },
  {
    accessorKey: "openRate",
    header: "Open Rate",
    Cell: ({ cell }) => cell?.getValue() + "%",
    // enableSorting: false
    size: 40,
  },
];

export const FilesColumns = [
  {
    accessorKey: "createdAt",
    header: "Uploaded on",
    enableSorting: false,
  },
  {
    accessorKey: "originalName",
    header: "Name",
    enableSorting: false,
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
  },
  {
    accessorKey: "progress",
    header: "Progress",
    enableSorting: false,
  },
  {
    accessorKey: "_",
    header: "Actions",
    enableSorting: false,
  },
];

export const CampaignsColumns = [
  {
    accessorKey: "campaignTitle",
    header: "Title",
    enableSorting: false,
  },
  {
    accessorKey: "campaignSubject",
    header: "Name",
    enableSorting: false,
  },
  {
    accessorKey: "totalProcessed",
    header: "Processed",
    enableSorting: false,
  },
  {
    accessorKey: "totalSent",
    header: "Sent",
    enableSorting: false,
  },
  {
    accessorKey: "totalOpen",
    header: "Opened",
    enableSorting: false,
  },
  {
    accessorKey: "replies",
    header: "Replied",
    enableSorting: false,
  },
  {
    accessorKey: "clicks",
    header: "Clicked",
    enableSorting: false,
  },
  {
    accessorKey: "openRate",
    header: "Open Rate",
    enableSorting: false,
  },
  {
    accessorKey: "clickRate",
    header: "Open Rate",
    enableSorting: false,
  },
  {
    accessorKey: "_",
    header: "Actions",
    enableSorting: false,
  },
];

export const campaignDetailsColumns = [
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: false,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    enableSorting: false,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    enableSorting: false,
  },
  // {
  //   accessorFn: (row) => {
  //     // If emailVerification is empty or the verified field is not present, return false
  //     return row?.emailVerification?.length > 0
  //       ? row.emailVerification[0].verified
  //       : false;
  //   },
  //   header: "Email Status",
  //   Cell: ({ cell }) => {
  //     const value = cell?.getValue();
  //     return value ? "Verified" : "Not Verified";
  //   },
  //   enableSorting: false,
  // },
  {
    accessorKey: "totalCampaigns",
    header: "Campaigns",
    enableSorting: false,
  },
  {
    accessorKey: "totalSent",
    header: "Total Sent",
    enableSorting: false,
  },
  {
    accessorKey: "totalOpens",
    header: "Total Opens",
    enableSorting: false,
  },
  {
    accessorKey: "totalClicks",
    header: "Total Clicks",
    enableSorting: false,
  },
  {
    accessorKey: "openRate",
    header: "Open Rate",
    Cell: ({ cell }) => cell?.getValue() + "%",
    enableSorting: false,
  },
];
