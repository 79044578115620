const mainDiv = {
    display: { md: 'flex', xs: 'none' },
    // marginTop: '1.3%',
    cursor: 'pointer',
    paddingRight: '3rem',
  },
  responsiveDiv = {
    color: '#007AFF',
    display: { xs: 'flex', md: 'none' },
    flexGrow: '1'
  },
  appBar = {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '1.5%',
    paddingInline: '1.75em'
  },
  responsiveNavStyles = {
    color: 'gray',
    textDecoration: 'none'
  },
  avatarDiv = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    marginTop:'1.3%',
    right: '0'
  },
  logoText = {
    fontFamily: 'A-Space-Demo-Light',
    color: 'black',
    fontSize: '23px',
    lineHeight: '34.5px',
    marginLeft: '0.25rem',
    minHeight: '100%',
    marginRight: '5em'
  },
  nameText = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%',
    color: 'gray'
  },
  NavStyles = ({ isActive }) => {
    return {
      color: isActive ? 'black' : 'gray',
      borderBottom: isActive ? '4px solid #007AFF' : 'none',
      textDecoration: 'none',
      minHeight: '5em',
      marginLeft: '4%',
      display: 'flex',
      alignItems: 'center'
    }
  }

export {
  mainDiv,
  responsiveDiv,
  NavStyles,
  appBar,
  responsiveNavStyles,
  avatarDiv,
  logoText,
  nameText
}
