import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/material'

export default function MuiSnackbar ({
  message,
  show,
  setShow,
  severity,
  error
}) {
  const handleClose = () => {
    setShow(false)
  }

  const action = (
    <>
      <IconButton
        size='small'
        aria-label='close'
        sx={{ color: 'white' }}
        onClick={handleClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      // message={message}
      action={action}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={
          error
            ? { width: '100%', bgcolor: 'red', color: 'white' }
            : {
                width: '100%',
                bgcolor: '#8ABA5C',
                color: 'white'
              }
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
