import { CircularProgress, Container } from "@mui/material";
import React from "react";

const Loader = ({ size, thickness, speed }) => {
  return (
    <Container sx={{ display: "flex" }}>
      <CircularProgress
        disableShrink
        size={size || 50}
        thickness={thickness || 7}
        sx={{
          margin: "0 auto",
          animation: `spin ${speed || 0.6}s linear infinite`, // Adjust speed here
          "@keyframes spin": {
            "0%": { transform: "rotate(0deg)" },
            "100%": { transform: "rotate(360deg)" },
          },
        }}
        color="primary"
      />
    </Container>
  );
};

export default Loader;
