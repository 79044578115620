import React, { useState } from 'react'
import Upload from '../../../Modals/Upload/Upload'
import { Button, IconButton } from '@mui/material'
import { Tooltip } from '@mui/material'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import PrimaryButton from '../Primary'
import UploadFileIcon from '@mui/icons-material/UploadFile';

export default function UploadCampaignStats ({ id }) {
  const [uploadModal, setUploadModal] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMap, setShowMap] = useState(false)
  return (
    <>
      <Tooltip title='Upload Campaign Stats'>
        <PrimaryButton
          onClick={() => setUploadModal(true)}
          icon={<UploadFileIcon />}
          text={'Stats'}
        />
      </Tooltip>
      <Upload
        open={uploadModal}
        setOpen={setUploadModal}
        setMapModal={setMapModal}
        showMap={showMap}
        setShowMap={setShowMap}
        uploading={uploading}
        setUploading={setUploading}
        campaignId={id}
      />
    </>
  )
}
