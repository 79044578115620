import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export default function UserCampaignStatsRow ({ row }) {
  return (
    <Table size='small' sx={{ backgroundColor: '#eff0ef' }}>
      <TableHead>
        <TableRow>
          <TableCell>Campaign Title</TableCell>
          <TableCell>Event</TableCell>
          <TableCell>Time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {row?.original?.campaigns?.map((campaign, i) =>
          campaign.campaignUserStats?.map(data => (
            <TableRow key={'user-table-' + data.id}>
              <TableCell>{campaign.campaignTitle}</TableCell>
              <TableCell>{data.event}</TableCell>
              <TableCell>{data.eventTime}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}
