import { Box, FormControl } from "@mui/material";
import React, { useState } from "react";
import FilterSelect from "../../UIKit/Select";
import FilterTextField from "../../UIKit/TextField";
import MuiAutoComplete from "../../../UIKit/MuiAutoComplete";
import FilterDatePicker from "../../UIKit/Datepicker";

export default function SubFilter({
  data,
  setValue,
  conditionId,
  subConditionId = 0,
  key,
  campaignUsers,
}) {
  const [step1, setStep1] = useState("");
  const [step2, setStep2] = useState("");

  // subConditionId && console.log('subConditionId', subConditionId)

  const step1Options = [
    { label: "First Name", value: "First Name" },
    { label: "Last Name", value: "Last Name" },
    { label: "Email", value: "Email" },
    // { label: "Email Status", value: "Email Status" },
    { label: "Campaign Name", value: "Campaign Name" },
    { label: "Campaign Event", value: "Campaign Event" },
    // { label: "Total Campaigns", value: "totalCampaigns" },
    { label: "Total Delivered", value: "totalSent" },
    { label: "Total Opens", value: "totalOpens" },
    { label: "Total Clicks", value: "totalClicks" },
    { label: "Total Unsubscribed", value: "unsubScribed" },
    // { label: 'Blacklisted', value: 'Blacklisted' },
    // { label: 'Created At', value: 'createdAt' },
    // { label: 'Total', value: 'total' },
    // { label: 'Conv Valid', value: 'convValid' },
    // { label: 'Conv Rate', value: 'convRate' },
    // { label: 'Gender', value: 'Gender' },
    // { label: 'Language', value: 'Language' },
    // { label: 'Verified', value: 'Verified' },
    // { label: 'Red Flagged', value: 'Red Flagged' },
    // { label: 'State', value: 'state' },
    // { label: 'Country', value: 'country' },
    // { label: 'User Status', value: 'User Status' },
    // { label: 'Campaign Start Date', value: 'Campaign Start Date' },
    // { label: 'Campaign End Date', value: 'Campaign End Date' },
    // { label: 'Contact', value: 'Contact' },
    // { label: 'Title', value: 'Title' },
    // { label: 'Company', value: 'Company' },
    // { label: 'Department', value: 'Department' }
  ];

  const step1AlternateOptions = [
    { label: "First Name", value: "First Name" },
    { label: "Last Name", value: "Last Name" },
    { label: "Email", value: "Email" },
    // { label: "Email Status", value: "Email Status" },
    { label: "Campaign Event", value: "Campaign Event" },
    // { label: "Total Delivered", value: "totalSent" },
    // { label: "Total Campaigns", value: "totalCampaigns" },
    // { label: "Total Opens", value: "totalOpens" },
    // { label: "Total Clicks", value: "totalClicks" },
    // { label: "Total Unsubscribed", value: "unsubScribed" },

    // { label: 'Blacklisted', value: 'Blacklisted' },
    // { label: 'Created At', value: 'createdAt' },
    // { label: 'Total', value: 'total' },
    // { label: 'Conv Valid', value: 'convValid' },
    // { label: 'Conv Rate', value: 'convRate' },
    // { label: 'Gender', value: 'Gender' },
    // { label: 'Language', value: 'Language' },
    // { label: 'Verified', value: 'Verified' },
    // { label: 'Red Flagged', value: 'Red Flagged' },
    // { label: 'State', value: 'state' },
    // { label: 'Country', value: 'country' }
    // { label: 'Contact', value: 'Contact' },
    // { label: 'Title', value: 'Title' },
    // { label: 'Company', value: 'Company' },
    // { label: 'Department', value: 'Department' }
  ];

  const step2Options = [
    "==",
    "!=",
    ">",
    "<",
    "<=",
    ">=",
    // 'In',
    // 'Not In',
    "Like",
    "Not Like",
    "Is Empty",
    "Is Not Empty",
  ];

  const step2AlternateOptions = [
    "==",
    "!=",
    // ">",
    // "<",
    // "<=",
    // ">=",
    // 'In',
    // 'Not In',
    "Like",
    "Not Like",
    "Is Empty",
    "Is Not Empty",
  ];

  const boolOPtions = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const emailStatus = ["Verified", "Not Verified"];
  const campaignEvents = [
    "processed",
    "dropped",
    "deferred",
    "bounce",
    "delivered",
    "open",
    "click",
    "unsubscribe",
    "spam report",
  ];

  const campaignEventis = [
    { label: "Processed", value: "processed" },
    { label: "Dropped", value: "dropped" },
    { label: "Deferred", value: "deferred" },
    { label: "Bounce", value: "bounce" },
    { label: "Delivered", value: "delivered" },
    { label: "Open", value: "open" },
    { label: "Click", value: "click" },
    { label: "Unsubscribe", value: "unsubscribe" },
    { label: "Spam report", value: "spam report" },
  ];

  const userCampaignFilters = [
    "Currently in campaign",
    "In previous campaign",
    "Not in campaign",
  ];
  // console.log(data)
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: ".5rem",
          margin: "0 auto",
          alignItems: "center",
          flexWrap: step1 === "Campaign Time" ? "wrap" : "nowrap",
        }}
        key={key}
      >
        <FormControl fullWidth style={{ width: "10rem" }}>
          <MuiAutoComplete
            options={campaignUsers ? step1AlternateOptions : step1Options}
            value={
              data?.category
                ? (campaignUsers ? step1AlternateOptions : step1Options).find(
                    (option) => option.value === data.category
                  )
                : null
            }
            size={"small"}
            fontSize={"12px"}
            width={"10rem"}
            label={"Category"}
            id={"campaign-filter-companies"}
            onChange={(newValue) => {
              const value = newValue ? newValue.value : "";
              setStep1(value);
              setValue(conditionId, subConditionId, "category", value);
            }}
          />
        </FormControl>

        <FormControl fullWidth style={{ width: "5rem" }}>
          <FilterSelect
            options={campaignUsers ? step2AlternateOptions : step2Options}
            value={data?.subOperator || ""}
            size={"small"}
            fontSize={"12px"}
            width={"5rem"}
            label={"is/not"}
            id={"campaign-filter-companies"}
            onChange={(e) => {
              setValue(
                conditionId,
                subConditionId,
                "subOperator",
                e.target.value
              );
              if (
                e.target.value === "Is Empty" ||
                e.target.value === "Is Not Empty"
              ) {
                setValue(conditionId, subConditionId, "value", "");
              }
              setStep2(e.target.value);
            }}
          />
        </FormControl>

        {step1 === "Email Status" ||
        (data && data.category === "Email Status") ? (
          <FormControl fullWidth style={{ width: "10rem" }}>
            <FilterSelect
              options={emailStatus}
              value={data?.value === 1 ? "Verified" : "Not Verified" || ""}
              // defaultValue={null}
              size={"small"}
              width={"10rem"}
              fontSize={"12px"}
              id={"campaign-filter-companies"}
              onChange={(e) => {
                setValue(
                  conditionId,
                  subConditionId,
                  "value",
                  e.target.value === "Verified" ? 1 : 0
                );
              }}
            />
          </FormControl>
        ) : step1 === "Campaign Start Date" ||
          step1 === "Campaign End Date" ||
          step1 === "Created At" ||
          (data && data?.category === "Campaign Start Date") ||
          data?.category === "Campaign End Date" ? (
          <FilterDatePicker
            label="Date"
            fontSize={"12px"}
            onChange={(e) => setValue(conditionId, subConditionId, "value", e)}
            value={data?.value || ""}
          />
        ) : step1 === "User Status" ||
          (data && data.category === "User Status") ? (
          <FormControl fullWidth style={{ width: "10rem" }}>
            <FilterSelect
              options={userCampaignFilters}
              value={data?.value || ""}
              size={"small"}
              fontSize={"12px"}
              width={"10rem"}
              label={"User Status"}
              id={"campaign-filter-companies"}
              onChange={(e) => {
                setValue(conditionId, subConditionId, "value", e.target.value);
              }}
            />
          </FormControl>
        ) : step1 === "Campaign Event" ||
          (data && data.category === "Campaign Event") ? (
          <FormControl fullWidth style={{ width: "10rem" }}>
            {/* <FilterSelect
              options={campaignEvents}
              value={data?.value || ''}
              size={'small'}
              fontSize={'12px'}
              width={'10rem'}
              label={'Campaign Event'}
              id={'campaign-events'}
              onChange={e => {
                setValue(conditionId, subConditionId, 'value', e.target.value)
              }}
            /> */}
            <MuiAutoComplete
              options={campaignEventis}
              value={
                campaignEventis.find(
                  (option) => option.value === data?.value
                ) || null
              }
              getOptionLabel={(option) => option.label || ""}
              size="small"
              fontSize="12px"
              width="10rem"
              label="Campaign Event"
              id={"campaign-events"}
              onChange={(newValue) => {
                const value = newValue ? newValue.value : "";
                setValue(conditionId, subConditionId, "value", value);
              }}
            />
          </FormControl>
        ) : step1 === "blacklisted" ||
          step1 === "red flagged" ||
          step1 === "verified" ||
          data?.category === "Blacklisted" ||
          data?.category === "Red Flagged" ||
          data?.category === "Verified" ? (
          <FormControl fullWidth style={{ width: "10rem" }}>
            <MuiAutoComplete
              options={boolOPtions}
              value={data?.value}
              // defaultValue={true}
              size={"small"}
              fontSize={"12px"}
              width={"10rem"}
              label={"Value"}
              id={"campaign-events"}
              onChange={(e) => {
                setValue(conditionId, subConditionId, "value", e.value);
              }}
            />
          </FormControl>
        ) : step2 === "Is Empty" || data.subOperator === "Is Empty" ? (
          <FilterTextField
            size={"small"}
            value={"null"}
            defaultValue={"null"}
            width={"10rem"}
            fontSize={"12px"}
            disabled={true}
          />
        ) : step2 === "Is Not Empty" || data.subOperator === "Is Not Empty" ? (
          <FilterTextField
            size={"small"}
            value={"not null"}
            width={"10rem"}
            fontSize={"12px"}
            disabled={true}
          />
        ) : (
          <FilterTextField
            size={"small"}
            value={data?.value || ""}
            width={"10rem"}
            fontSize={"12px"}
            onChange={(e) => {
              setValue(conditionId, subConditionId, "value", e.target.value);
            }}
          />
        )}
      </Box>
    </>
  );
}
