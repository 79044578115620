import { createSlice } from "@reduxjs/toolkit";

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    progress: 0,
    file: {},
    fileData: {},
    fileHeaders: [],
    Repeating: [],
    files: [],
  },
  reducers: {
    setUploadProgress: (state, action) => {
      state.progress = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.file = action.payload;
    },
    setUploadedLemlistFile: (state, action) => {
      state.file = action.payload;
    },
    setFileData: (state, action) => {
      state.fileData = action.payload;
    },
    setFileRepeating: (state, action) => {
      state.Repeating = action.payload;
    },
    clearRepeating: (state) => {
      state.Repeating = [];
    },
    setFileHeaders: (state, action) => {
      state.fileHeaders = action.payload;
    },
    // updateFileProgress: (state, action) => {
    //   console.log("In Slice Payload >>>>>",action.payload);
    //   const { id, processedCampaigns, totalCampaigns, status } = action.payload;
    //   const progress = totalCampaigns
    //     ? (processedCampaigns / totalCampaigns) * 100
    //     : 0;

    //   const updatedFile = { id, progress, status, updatedAt: Date.now() };

    //   state.files = state.files.map((file) =>
    //     file.id === id ? updatedFile : file
    //   );

    //   if (!state.files.some((file) => file.id === id)) {
    //     state.files.push(updatedFile);
    //   }
    //   console.log("Upload Progress >>>>>",progress);
    // },
    updateFileProgress: (state, action) => {
      const { id, processedCampaigns, totalCampaigns, status } = action.payload;
      const progress = totalCampaigns
        ? (processedCampaigns / totalCampaigns) * 100
        : 0;

      const updatedFile = { id, progress, status, updatedAt: Date.now() };

      // Update existing file or add a new one
      const fileIndex = state.files.findIndex((file) => file.id === id);
      if (fileIndex !== -1) {
        state.files[fileIndex] = updatedFile;
      } else if (progress < 100) {
        state.files.push(updatedFile);
      }

      // Remove completed files
      state.files = state.files.filter((file) => file.progress < 100);
    },

    clearFileProgress: (state) => {
      state.files = [];
      console.log("state cleaned");
    },
  },
});

export const {
  setUploadProgress,
  setUploadedFile,
  setUploadedLemlistFile,
  setFileData,
  setFileRepeating,
  clearRepeating,
  setFileHeaders,
  updateFileProgress,
  clearFileProgress,
} = fileSlice.actions;
export default fileSlice.reducer;
