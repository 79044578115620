import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateFileProgress,
} from "../RTKQuery/FileService/fileSlice";
import {
  subscribeToFileStatus,
  unsubscribeFromFileStatus,
} from "../Services/websockets";

export const useFileProgress = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Callback to handle WebSocket progress updates
    const handleProgressUpdate = (progressUpdate) => {
      dispatch(updateFileProgress(progressUpdate.data));
      console.log("Progress Update:", progressUpdate);
    };

    // Subscribe to WebSocket
    subscribeToFileStatus(handleProgressUpdate);

    // Cleanup logic to unsubscribe ONLY
    return () => {
      unsubscribeFromFileStatus(handleProgressUpdate);
      console.log("WebSocket unsubscribed");
    };
  }, [dispatch]);
};
