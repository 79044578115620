import React from "react";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

const ActionButton = ({ id }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/campaign/details/${id}`);
  };

  return (
    <>
      <Box>
        <Tooltip title="View Details">
          <IconButton onClick={handleClick} size="small">
            {/* <VisibilityOutlinedIcon /> */}
            <PlagiarismOutlinedIcon style={{ color: "#8ABA5C" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default ActionButton;
