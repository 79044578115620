import React from 'react'
import { MaterialReactTable } from 'material-react-table'
import { Box } from '@mui/material'
import UserCampaignStatsRow from '../../../constants/SubTables/userCampaignStats'

const DataTable = ({
  columns,
  data,
  selectable = false,
  setSorting,
  totalData,
  sorting,
  pagination,
  setLoader,
  manualPagination = true,
  setPagination,
  rowSelection = {},
  enableCollapsibleRow = true,
  setRowSelection,
  density
}) => {
  const onPaginationChange = rows => {
    setLoader(true)
    setPagination(rows)
  }

  return (
    <MaterialReactTable
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          // boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px`,
          borderRadius: '5px'
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          backgroundColor: '#f0f0f0',
          color: '#333',
          fontWeight: 'bold',
          textAlign: 'left !important',
          padding: '8px'
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          padding: '8px',
          color: '#333'
        }
      }}
      columns={columns}
      data={data}
      enableRowSelection={selectable ? true : false}
      onRowSelectionChange={setRowSelection}
      getRowId={row => row.id}
      enableSorting
      enableGlobalFilter={false}
      enableColumnFilters={false}
      enableDensityToggle={false}
      manualPagination={manualPagination}
      rowCount={totalData}
      onPaginationChange={onPaginationChange}
      state={{
        pagination,
        sorting,
        rowSelection
      }}
      initialState={{
        density: 'comfort'
      }}
      muiPaginationProps={{ rowsPerPageOptions: [10, 25, 50, 100, 250, 500] }}
      manualSorting
      onSortingChange={setSorting}
      renderDetailPanel={
        enableCollapsibleRow
          ? ({ row }) =>
              row?.original?.campaigns?.length > 0 ? (
                <Box sx={{ backgroundColor: '#eff0ef' }}>
                  <UserCampaignStatsRow row={row} />
                </Box>
              ) : null
          : undefined
      }
    />
  )
}

export default DataTable
