import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_BACKEND_URL; // Backend URL
const token = localStorage.getItem("token"); // Fetch token if applicable

const socket = io(SOCKET_URL, {
  transports: ["websocket"],
  autoConnect: true,
  auth: {
    token: `Bearer ${token}`, // Pass the token as a Bearer token
  },
});

// Subscribe to the 'file-processing-progress' event
export const subscribeToFileStatus = (callback) => {
  console.log("Socket On")
  socket.on("file-processing-progress", callback);
};

// Unsubscribe from the 'file-processing-progress' event
export const unsubscribeFromFileStatus = (callback) => {
  socket.off("file-processing-progress", callback);
};

// Handle connection errors and reconnection attempts
socket.on("connect_error", (err) => {
  console.error("Connection Error:", err.message);
});

socket.on("connect", () => {
  console.log("Connected to WebSocket server");
});

// Disconnect the socket connection
export const disconnectSocket = () => {
  if (socket.connected) {
    socket.disconnect();
  }
};

// Reconnect manually if needed (for example, after an error)
export const reconnectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};

export default socket;
