const mainContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height:'100vh',
    gap:'3rem'
  },
  loginForm = {
    width: { md: "40%", xs: "90%" },
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-between",
    gap:'1rem',
    minHeight: "22em",
    padding: "1rem",
  },
  logo = { maxWidth: "15em", maxHeight: "15em" },
  headerText = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  welcomeText = {
    textAlign: "center",
    fontSize: { md: "2em", xs: "2em" },
    // fontWeight: "bold",
  },
  forgotPassText = { color: "gray", textAlign: "center", marginTop: "1em" };

export {
  loginForm,
  logo,
  mainContainer,
  forgotPassText,
  welcomeText,
  headerText,
};
