import React from 'react'
import { TextField } from '@mui/material'

export default function FilterTextField ({
  label,
  size,
  value,
  onChange,
  defaultValue,
  disabled = false,
  width,
  fontSize
}) {
  return (
    <TextField
      style={{
        backgroundColor: 'white',
        width: width ? width : 'auto',
        margin:0
      }}
      disabled = {disabled}
      inputProps={{
        style: { fontSize: fontSize, borderRadius: 0 }
      }}
      label={label}
      margin='normal'
      size={size}
      value={value}
      // sx={InputField}
      onChange={onChange}
    />
  )
}
